import styled from "styled-components"
import { BREAKPOINTS } from "../../constants"
import TagIcon from "../../../static/website_assets/Tag.svg"
import FigmaIcon from "../../../static/website_assets/FigmaLogo.svg"
import FramerIcon from "../../../static/website_assets/FramerLogo.svg"
import WebsiteIcon from "../../../static/website_assets/Globe.svg"
import ExpandPointer from "../../../static/website_assets/ExpandPointer.svg"
import LockIcon from "../../../static/website_assets/LockKey.svg"
import { Link } from "gatsby"

export const ProjectContent = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  z-index: 100;
  > * + * {
    margin-top: 1rem;
  }
`

export const Title = styled.h3`
  margin: 0 !important;
  font-size: var(--heading-4);
  line-height: calc(var(--heading-4) * 1.2);
  letter-spacing: 0.024em;
  @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    font-size: var(--heading-1);
    line-height: calc(var(--heading-1) * 1.2);
    letter-spacing: 0.024em;
  }
  cursor: pointer;
  transition: color 240ms ease-in-out;
  :hover {
    color: var(--color-theme-accent);
  }
`
export const Description = styled.p`
  background: var(--color-background-48-dp-elevation);
  padding: 1rem;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  white-space: break-spaces;
  /* overflow-wrap: anywhere; */
`
export const Tags = styled.ul`
  list-style: none;
  /* max-width: 80%; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0;
  flex-wrap: wrap;
  > * {
    padding: 0.5rem 0;
  }
  > * + * {
    margin: 0 0 0 0.5rem;
  }
  @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    max-width: 100%;
  }
  :before {
    opacity: 0.72;
    content: ".";
    display: inline-block;
    margin-right: 0.6rem;
    height: calc(var(--tags)+"4px");
    width: 20px;
    background-color: var(--color-theme-text);
    mask: url(${TagIcon}) no-repeat center;
  }
`
export const Tag = styled.li`
  font-size: var(--tags);
  overflow: hidden;
  > a {
    color: var(--color-theme-text);
    opacity: 0.72;
    transition: color 240ms ease-in-out;
    ::after {
      transition: all ease-in-out 0.2s;
      background: none repeat scroll 0 0 var(--color-theme-accent);
      content: "";
      display: block;
      padding-top: 3.2px;
      width: 100%;
      transform: translateX(-120%);
      height: 0.2px;
    }
    :hover {
      color: var(--color-theme-accent);
      opacity: 1;
      ::after {
        transform: translateX(0);
        width: 100%;
      }
    }
  }
`
export const ExternalLinks = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    margin-right: 0.5rem;
  }
`
export const ExtLink = styled.a`
  opacity: 0.72;
  content: ".";
  display: inline-block;
  margin-right: 0.6rem;
  height: 2rem;
  width: 2rem;
  background-color: var(--color-theme-text);
  transition: background-color 240ms ease-in-out;
  :hover,
  :active {
    opacity: 1;
    background-color: var(--color-theme-accent);
    transition: background-color 240ms ease-in-out;
  }
`

export const FigmaLink = styled(ExtLink)`
  --webkit-mask: url(${FigmaIcon}) no-repeat center;
  mask: url(${FigmaIcon}) no-repeat center;
`
export const FramerLink = styled(ExtLink)`
  --webkit-mask: url(${FramerIcon}) no-repeat center;
  mask: url(${FramerIcon}) no-repeat center;
`
export const WebsiteLink = styled(ExtLink)`
  --webkit-mask: url(${WebsiteIcon}) no-repeat center;
  mask: url(${WebsiteIcon}) no-repeat center;
`
export const ViewCaseStudy = styled(Link)`
  font-family: "Work Sans", sans-serif;
  font-size: var(--paragraph);
  line-height: calc(var(--paragraph) * 1.4);
  font-weight: bold;
  letter-spacing: 0.032em;
  color: var(--color-theme-text);
  /* border: solid 2px var(--color-theme-text);
  border-radius: 4px;
  padding: 0.5rem 1rem; */
  width: fit-content;
  transition: color 240ms ease-in-out, border-color 240ms ease-in-out;
  :hover {
    color: var(--color-theme-accent);
    /* border-color: var(--color-theme-accent); */
    :after {
      background-color: var(--color-theme-accent);
    }
  }
  :after {
    content: ".";
    display: inline-block;
    margin-left: 0.2rem;
    height: calc(var(--paragraph)+"4px");
    width: 20px;
    transition: background-color 240ms ease-in-out;
    background-color: var(--color-theme-text);
    mask: url(${props => (props.locked ? LockIcon : ExpandPointer)}) no-repeat
      center;
  }
`
