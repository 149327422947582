import { graphql, Link, navigate, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { BREAKPOINTS } from "../../constants"
import Thumbnail, { ThumbnailWrapper } from "./Thumbnail"
import React from "react"
import {
  Description,
  ProjectContent,
  Tag,
  Tags,
  Title,
  // ExternalLinks,
  // FigmaLink,
  // FramerLink,
  // WebsiteLink,
  // ViewCaseStudy,
} from "./ProjectContent"
import { Project } from "./Project"

export const ProjectsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 72vw;
  justify-self: center;
  > * {
    margin-bottom: 4.5rem;
  }
  margin: var(--safety-padding) 0;
  @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    max-width: unset;
    margin-top: 1rem !important;
    > * {
      margin-bottom: 4rem;
    }
  }
  @media (max-width: 1080px) and (min-width: ${BREAKPOINTS["small-viewport"]}) {
    margin: var(--safety-padding);
  }
`

export const Projects = props => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: { frontmatter: { type: { eq: "project" } } }
        sort: {
          fields: [frontmatter___priority, frontmatter___date]
          order: [ASC, DESC]
        }
      ) {
        nodes {
          slug
          excerpt(pruneLength: 280)
          frontmatter {
            title
            date
            description
            type
            tags
            locked
            externalLinks {
              figma
              website
            }
            coverImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          id
        }
      }
    }
  `)

  // <Link to={`/projects/${node.slug.split("/")[1]}`}></Link>

  const projectsData = props.projectsData
    ? props.projectsData
    : data.allMdx.nodes

  const cardVariants = {
    offscreen: {
      opacity: 0,
      y: 20,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  }

  return (
    <ProjectsWrapper className="full-bleed">
      {projectsData.map((node, index) => {
        const tmb = node.frontmatter.coverImage.childImageSharp.gatsbyImageData
        const tagsArrayLength = node.frontmatter.tags.length

        /* const figmaLink = node.frontmatter.externalLinks
          ? node.frontmatter.externalLinks.figma
          : undefined
        const framerLink = node.frontmatter.externalLinks
          ? node.frontmatter.externalLinks.framer
          : undefined
        const websiteLink = node.frontmatter.externalLinks
          ? node.frontmatter.externalLinks.website
          : undefined */

        const lockedSuffix = node.frontmatter.locked ? "protected" : "public"

        return (
          <Project key={node.id}>
            <ThumbnailWrapper
              className="image"
              onClick={() => {
                navigate(`/projects/${lockedSuffix}/${node.slug.split("/")[1]}`)
              }}
              tabIndex="0"
              onKeyDown={e => {
                if (e.key === "Enter") {
                  navigate(
                    `/projects/${lockedSuffix}/${node.slug.split("/")[1]}`
                  )
                }
              }}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.2 }}
              variants={cardVariants}
            >
              <Thumbnail alt={node.frontmatter.title} image={tmb}></Thumbnail>
            </ThumbnailWrapper>
            <ProjectContent
              className="content"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.02 }}
              variants={cardVariants}
            >
              <Title
                onClick={() => {
                  navigate(
                    `/projects/${lockedSuffix}/${node.slug.split("/")[1]}`
                  )
                }}
              >
                {node.frontmatter.title}
              </Title>
              <Description locked={node.frontmatter.locked ? 1 : 0}>
                {node.frontmatter.description}
              </Description>
              {node.frontmatter.tags && (
                <Tags>
                  {node.frontmatter.tags.map((tag, index) => {
                    const comma = index !== tagsArrayLength - 1 ? "," : ""
                    return (
                      <Tag key={`${node.id}-${tag}`}>
                        <Link to={`/tags/${tag}`}>{`${tag}${comma}`}</Link>
                      </Tag>
                    )
                  })}
                </Tags>
              )}
              {/* {!!node.frontmatter.externalLinks && (<ExternalLinks>
                {!!figmaLink && (
                  <FigmaLink
                    href={figmaLink}
                    target="_blank"
                    rel="noreferrer"
                    alt={`Figma link to ${node.frontmatter.title} project`}
                    aria-label={`Figma link to ${node.frontmatter.title} project`}
                  />
                )}
                {!!framerLink && (
                  <FramerLink
                    href={framerLink}
                    target="_blank"
                    rel="noreferrer"
                    alt={`Framer link to ${node.frontmatter.title} project`}
                    aria-label={`Framer link to ${node.frontmatter.title} project`}
                  />
                )}
                {!!websiteLink && (
                  <WebsiteLink
                    href={websiteLink}
                    target="_blank"
                    rel="noreferrer"
                    alt={`Website link for ${node.frontmatter.title} project`}
                    aria-label={`Website link to ${node.frontmatter.title} project`}
                  />
                )}
              </ExternalLinks>)} */}
              {/* <ViewCaseStudy
                to={`/projects/${lockedSuffix}/${node.slug.split("/")[1]}`}
                alt={`View Case Study for ${node.frontmatter.title} project.`}
                locked={node.frontmatter.locked ? 1 : 0}
              >
                View Case Study
              </ViewCaseStudy> */}
            </ProjectContent>
          </Project>
        )
      })}
    </ProjectsWrapper>
  )
}

export default Projects
