import styled from "styled-components"
import { BREAKPOINTS } from "../../constants"

export const Project = styled.div`
  z-index: 33;

  @media (min-width: ${BREAKPOINTS["small-viewport"]}) {
    margin-top: 4rem;
    margin-bottom: 12rem;
    display: grid;
    gap: 4rem;
    grid-template-columns: [left] 12fr [mid-left] 1fr [mid-right] 12fr [right];
    align-items: center;

    > * {
      grid-row: 1;
    }
    :nth-child(odd) {
      > .content {
        grid-column: mid-right / right;
        > * {
          align-self: flex-start;
        }
      }
      > .image {
        grid-column: left / mid-right;
        z-index: 200;
      }
    }
    :nth-child(even) {
      > .content {
        grid-column: left / mid-left;
        text-align: left;
        > ul {
          align-self: flex-start;
          justify-content: flex-start;
          > li {
            margin: 0 0.5rem 0 0;
          }
        }
      }
      > .image {
        grid-column: mid-left / right;
        z-index: 200;

      }
    }
  }
  @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    margin: 0 1rem;
    margin-top: 1rem;
    margin-bottom: 5rem;
    display: grid;
    grid-template-rows: [top] 1fr [mid] 1fr [bottom];
    > * {
      grid-column: 1;
    }
    > * + * {
      margin: 0;
      margin-top: 1.5rem;
    }
    > .image {
      grid-row: top / mid;
    }
    > .content {
      grid-row: mid / bottom;
      text-align: start;
      > ul {
        justify-content: flex-start;
        width: 100%;
        li {
          margin: 0 0.5rem 0 0;
        }
      }
    }
    @supports (-webkit-hyphens: none) {
      display: flex;
      flex-direction: column;
      ::first-child {
        margin: 0;
        margin-top: 4rem;
      }
      > * + * {
        margin: 0;
        margin-top: 2rem;
      }
    }
  }
`
