import React from "react"
import { SectionHeader } from "../components/Common"
import Head from "../components/head"
import Projects from "../components/projectListing/Projects"
import Layout from "../layouts/layout"

function ProjectsPage() {
  return (
    <Layout>
      <Head title="Projects" />
      <SectionHeader>All Projects</SectionHeader>
      <Projects />
    </Layout>
  )
}

export default ProjectsPage
