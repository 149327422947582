import { motion } from "framer-motion"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

export const ThumbnailWrapper = styled(motion.div)`
  height: 100%;
`

const Thumbnail = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  box-shadow: 4px 4px 4.6169px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  > picture > img {
    border-radius: 0.5rem;
  }
  /* overflow: hidden; */
  overflow: visible !important;
  cursor: pointer;
  transition: transform 240ms ease-in-out;

  :hover {
    filter: drop-shadow(8px 8px 8px rgb(0 0 0 / 12%));
    transform: translate(-5%, -5%);
    transition: transform 240ms ease-in-out;
    ::before {
      transform: translate(20px, 20px);
      animation: hue 8s infinite linear;
      transition: transform 240ms ease-in-out;
    }
  }
  :active {
    filter: drop-shadow(8px 8px 8px rgb(0 0 0 / 12%));
    transform: translate(-2%, -2%);
    transition: transform 120ms ease-in-out;
    ::before {
      transform: translate(10px, 10px);
      transition: transform 120ms ease-in-out;
    }
  }
  ::before {
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background: var(--color-theme-gradient-2);
    border-radius: 0.5rem;
    opacity: 0.72;
    transition: transform 240ms ease-in-out;
  }
  /* ::after,
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-theme-gradient-2);
    mix-blend-mode: difference;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;
    opacity: 0.5;
    border-radius: 4px;
    transition: all 240ms ease-in-out;
  }
  ::before {
    mix-blend-mode: color;
    z-index: 2;
    opacity: 1;
  } */

  @supports (-webkit-hyphens: none) {
    ::after,
    ::before {
      opacity: 0;
    }
    border-radius: 4px;
    height: fit-content;
  }
`

export default Thumbnail
